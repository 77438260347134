import router from 'next/router';
import { Link } from 'components/Link';
import {
  Drawer,
  List,
  Divider,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { SideNavItem } from './SideNavItem';
import type { NavItem } from '../nav-items';

const useStyles = makeStyles<Theme, Pick<Props, 'colors'>>(() =>
  createStyles({
    drawer: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      width: 88,
      backgroundColor: (props) => props.colors.base,
    },
  }),
);

export type ColorSettings = {
  base: string;
  active: string;
  hover: string;
};

type Props = {
  navItems: NavItem[];
  colors: ColorSettings;
};

export const SideNav = ({ navItems, colors }: Props): JSX.Element => {
  const classes = useStyles({ colors });

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawer,
      }}
    >
      <List>
        {navItems.map((item, index) => (
          <div key={index}>
            <Link
              href={item.path}
              external={item.target != null}
              target={item.target}
            >
              <SideNavItem
                icon={item.icon}
                label={item.label}
                active={isActive(
                  router.pathname,
                  item.path,
                  item.isActiveOptions,
                )}
                colors={colors}
              />
            </Link>
            <Divider />
          </div>
        ))}
      </List>
    </Drawer>
  );
};

function isActive(
  currentPath: string,
  menuItemPath: string,
  { exact, judgeFunc }: NavItem['isActiveOptions'] = {},
): boolean {
  if (judgeFunc) {
    return judgeFunc(currentPath, menuItemPath);
  }

  if (exact) {
    return currentPath === menuItemPath;
  }

  return (
    currentPath === menuItemPath || currentPath.startsWith(menuItemPath + '/')
  );
}
