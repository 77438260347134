export const accountMenuItems = [
  {
    label: '名前変更',
    path: '/members/edit-name',
  },
  {
    label: 'メールアドレス変更',
    path: '/members/edit-email',
  },
  {
    label: 'パスワード変更',
    path: '/company/password/edit',
  },
];
