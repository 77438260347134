import { createContext } from 'react';
import { LoginUserContext_LoginUserFragment } from 'generated/graphql';

export type LoginUserWithToken = LoginUserContext_LoginUserFragment & {
  token: string;
};

export const LoginUserContext = createContext<LoginUserWithToken | undefined>(
  undefined,
);
