import type { NavItem } from './types';
import { companyNavItems } from './companyNavItems';
import { crassoneNavItems } from './crassoneNavItems';
import { insuranceAgencyNavItems } from './insuranceAgencyNavItems';
import { AppMode } from 'contexts/AppModeContext';
import { LoginUserWithToken } from 'contexts/LoginUserContext';
import { hasPermission } from 'utils/hasPermission';

export const getNavItems = (
  appMode: AppMode,
  loginUser: LoginUserWithToken | null,
): NavItem[] => {
  let items: NavItem[];

  switch (appMode) {
    case 'company':
      items = companyNavItems;
      break;
    case 'crassone':
      items = crassoneNavItems;
      break;
    case 'insurance-agency':
      items = insuranceAgencyNavItems;
      break;
  }

  return items.filter(
    (item) =>
      item.accessPolicy == null ||
      (loginUser != null &&
        loginUser.__typename === 'Member' &&
        hasPermission(
          loginUser,
          item.accessPolicy.feature,
          item.accessPolicy.accessLevel,
        )),
  );
};
