import {
  Dashboard as DashboardIcon,
  PermPhoneMsg as PermPhoneMsgIconIcon,
  Description as DescriptionIcon,
} from '@material-ui/icons';
import type { NavItem } from './types';

export const insuranceAgencyNavItems: NavItem[] = [
  {
    icon: DashboardIcon,
    label: 'ダッシュボード',
    path: '/insurance-agency',
  },
  {
    icon: PermPhoneMsgIconIcon,
    label: '保険申請',
    path: '/insurance-agency/application-requests',
  },
  {
    icon: DescriptionIcon,
    label: '案件',
    path: '/insurance-agency/projects',
  },
];
