import { ReactNode } from 'react';
import NextLink from 'next/link';
import styled from 'styled-components';

// Navigationのように、外部リンクか内部リンクか動的に決めたい場合に使用。
// あらかじめどちらか判っている場合は直接next/linkや<a>などを利用してください。

const UndressedA = styled.a`
  text-decoration: none;
  color: inherit;
`;

type Props = {
  href: string;
  external?: boolean;
  target?: string;
  children: ReactNode;
};

export const Link = ({
  href,
  external,
  target,
  children,
}: Props): JSX.Element => {
  if (external) {
    return (
      <UndressedA href={href} target={target}>
        {children}
      </UndressedA>
    );
  } else {
    return (
      <NextLink href={href} passHref>
        <UndressedA>{children}</UndressedA>
      </NextLink>
    );
  }
};
