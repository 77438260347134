import {
  DonutSmall as DonutSmallIcon,
  List as ListIcon,
  Iso as IsoIcon,
  Description as DescriptionIcon,
  People as PeopleIcon,
  Subject as SubjectIcon,
  DeveloperBoard as DeveloperBoardIcon,
  LaptopMac as LaptopMacIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  Contacts as ContactsIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';
import type { NavItem } from './types';
import { Feature, AccessLevel } from 'utils/hasPermission';
import { pagesPath } from 'utils/$path';

export const companyNavItems: NavItem[] = [
  {
    icon: DonutSmallIcon,
    label: 'ダッシュボード',
    path: '/',
    isActiveOptions: { exact: true },
  },
  {
    icon: ListIcon,
    label: '案件',
    path: pagesPath.projects.$url().pathname,
  },
  {
    icon: ListAltIcon,
    label: 'ToDo',
    path: pagesPath.todos.$url().pathname,
  },
  {
    icon: IsoIcon,
    label: '見積書',
    path: pagesPath.estimates.$url().pathname,
    accessPolicy: {
      feature: Feature.Estimate,
      accessLevel: AccessLevel.Read,
    },
  },
  {
    icon: DescriptionIcon,
    label: '契約',
    path: pagesPath.contracts.$url().pathname,
    accessPolicy: {
      feature: Feature.ContractEsignature,
      accessLevel: AccessLevel.Read,
    },
  },
  {
    icon: ReceiptIcon,
    label: '請求書',
    path: pagesPath.invoices.$url().pathname,
    accessPolicy: {
      feature: Feature.Invoice,
      accessLevel: AccessLevel.Read,
    },
  },
  {
    icon: ContactsIcon,
    label: '顧客',
    path: pagesPath.customers.$url().pathname,
  },
  {
    icon: PeopleIcon,
    label: '従業員一覧',
    path: pagesPath.members.$url().pathname,
  },
  {
    icon: SubjectIcon,
    label: '利用規約',
    path: pagesPath.tos.$url().pathname,
  },
  {
    icon: SettingsIcon,
    label: '設定',
    path: pagesPath.company_settings.$url().pathname,
  },
];

if (process.env.NODE_ENV === 'development') {
  companyNavItems.push({
    icon: DeveloperBoardIcon,
    label: 'admin',
    path: pagesPath.dev_admin.$url().pathname,
  });
}

// (機能リリースまではmainにマージする前にfalseにすること)
const DISPLAY_NAV_ITEM = false;
if (DISPLAY_NAV_ITEM) {
  companyNavItems.splice(4, 1, {
    icon: LaptopMacIcon,
    label: '電子契約書',
    path: '/e-contracts',
  });
}
