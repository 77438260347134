import { FC, CSSProperties } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
} from '@material-ui/core';

interface IconProps {
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
  style?: CSSProperties;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(1),
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
    },
  }),
);

type Props = {
  label: string;
  icon?: FC<IconProps>;
  onClick?(): void;
};

export const AppMenuItem = ({
  label,
  icon: Icon,
  onClick,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ListItem button onClick={onClick} className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        {Icon && <Icon />}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
