import { useEffect, ReactNode } from 'react';
import router from 'next/router';
import { useSession } from 'next-auth/client';
import { AppModeContext, AppMode } from 'contexts/AppModeContext';
import { LoginUserContext } from 'contexts/LoginUserContext';
import { Layout } from 'components/layouts/Layout';
import { HeadPageTitle } from 'components/HeadPageTitle';
import { useSecuredPageQuery } from 'generated/graphql';

type Props = {
  children: NonNullable<ReactNode>;
  appMode: AppMode;
  title?: string;
};

export const SecuredPage = ({
  children,
  appMode,
  title = '',
}: Props): JSX.Element | null => {
  const [session, loading] = useSession();

  useEffect(() => {
    const loginPageUrl = appMode === 'company' ? '/login' : `/${appMode}/login`;

    if (!loading) {
      if (!session) {
        // ログインしていない
        router.push(loginPageUrl);
      } else if (session.user.role !== `${appMode}-member`) {
        // 他のroleでログイン済み
        router.push(loginPageUrl);
      }
    }
    // eslint-disable-next-line
  }, [loading, session]);

  const { data } = useSecuredPageQuery({
    context: {
      clientName: appMode === 'insurance-agency' ? 'insuranceAgency' : appMode,
    },
    errorPolicy: 'all',
  });
  const loginUser = data?.getLoginUser || null;
  const loginUserWithToken =
    session && loginUser
      ? { ...loginUser, ...{ token: session.token } }
      : undefined;

  if (loading || !session || !loginUser) return null;

  return (
    <LoginUserContext.Provider value={loginUserWithToken}>
      <AppModeContext.Provider value={appMode}>
        <HeadPageTitle title={title} />
        <Layout>{children}</Layout>
      </AppModeContext.Provider>
    </LoginUserContext.Provider>
  );
};
