import {
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  FileCopy as FileIcon,
  Business as BusinessIcon,
  Lock as LockIcon,
  People as PeopleIcon,
} from '@material-ui/icons';
import type { NavItem } from './types';

export const crassoneNavItems: NavItem[] = [
  {
    icon: DashboardIcon,
    label: 'ダッシュボード',
    path: '/crassone',
  },
  {
    icon: DescriptionIcon,
    label: '案件一覧',
    path: '/crassone/projects',
  },
  {
    icon: FileIcon,
    label: '契約書類',
    path: '/crassone/uploaded-contract-documents',
  },
  {
    icon: BusinessIcon,
    label: '工事会社一覧',
    path: '/crassone/companies',
  },
  {
    icon: PeopleIcon,
    label: 'オペレータ-一覧',
    path: '/crassone/operators',
  },
  {
    icon: LockIcon,
    label: 'パスワード変更',
    path: '/crassone/password/edit',
  },
];
