import { useState } from 'react';
import { signOut } from 'next-auth/client';
import { Link } from 'components/Link';
import {
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { PermIdentity as PermIdentityIcon } from '@material-ui/icons';
import { clearUuids } from 'lib/uuid';
import { accountMenuItems } from './accountMenuItems';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuList: {
      marginTop: '1.75rem',
    },
    menuItem: {
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
  }),
);

export const AccountMenu = (): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    clearUuids();
    signOut();
  };
  return (
    <>
      <IconButton
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <PermIdentityIcon fontSize="medium" />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        className={classes.menuList}
      >
        {accountMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            className={classes.menuItem}
          >
            <Link href={item.path}>{item.label}</Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleSignOut} className={classes.menuItem}>
          ログアウト
        </MenuItem>
      </Menu>
    </>
  );
};
