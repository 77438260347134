import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { useReactiveVar } from '@apollo/client';
import { pageTitleVar } from 'client-state';
import { useScreenSize } from 'hooks/useScreenSize';
import { useAppMode, AppMode } from 'hooks/useAppMode';
import { useLoginUser } from 'hooks/useLoginUser';
import { H1 } from 'components/presentation';
import { AppBar } from './AppBar';
import { SideNav, ColorSettings } from './SideNav';
import { getNavItems } from './nav-items';
import { baseBackgroundGrey, crassoneNavy } from 'color-palette';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  sideColumn: {
    width: 88,
  },
  mainColumn: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      flexGrow: 'inherit',
    },
  },
  topPane: {
    height: 56,
    backgroundColor: baseBackgroundGrey,
  },
  mainPane: {
    width: '100%',
    height: 'calc(100vh - 56px)',
    backgroundColor: baseBackgroundGrey,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      overflow: 'inherit',
    },
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

const colors: Record<AppMode, ColorSettings> = {
  company: {
    base: crassoneNavy.main,
    active: crassoneNavy.dark,
    hover: crassoneNavy.light,
  },
  crassone: {
    base: '#C23D36',
    active: '#802924',
    hover: '#D47772',
  },
  'insurance-agency': {
    base: '#006C60',
    active: '#004840',
    hover: '#5AA099',
  },
};

type Props = {
  children: NonNullable<ReactNode>;
};

export const Layout = ({ children }: Props): JSX.Element | null => {
  const classes = useStyles();
  const appMode = useAppMode();
  const { upSm } = useScreenSize();
  const loginUser = useLoginUser();

  const pageTitle = useReactiveVar(pageTitleVar);

  if (appMode == null) return null;

  const navItems = getNavItems(appMode, loginUser);

  return (
    <div className={classes.root}>
      {upSm && (
        <div className={classes.sideColumn}>
          <SideNav navItems={navItems} colors={colors[appMode]} />
        </div>
      )}
      <div className={classes.mainColumn}>
        <header className={classes.topPane}>
          <AppBar navItems={navItems} appMode={appMode} />
        </header>
        <main className={classes.mainPane}>
          {upSm && <H1>{pageTitle}</H1>}
          {children}
        </main>
      </div>
    </div>
  );
};
