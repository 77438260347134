import type { Query as Query0 } from '../pages/contracts/new'
import type { OptionalQuery as OptionalQuery1 } from '../pages/estimates/new'
import type { OptionalQuery as OptionalQuery2 } from '../pages/invoices/new'
import type { OptionalQuery as OptionalQuery3 } from '../pages/projects/[id]/[[...tab]]'
import type { OptionalQuery as OptionalQuery4 } from '../pages/todos'

export const pagesPath = {
  access_policies: {
    edit: {
      $url: (url?: { hash?: string }) => ({ pathname: '/access-policies/edit' as const, hash: url?.hash })
    }
  },
  accidents: {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/accidents/[id]' as const, query: { id }, hash: url?.hash })
    }),
    application_request_documents: {
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/accidents/application-request-documents/new' as const, hash: url?.hash })
      }
    }
  },
  company: {
    password: {
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/company/password/edit' as const, hash: url?.hash })
      }
    }
  },
  company_settings: {
    company_info: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/company-info' as const, hash: url?.hash })
    },
    company_logo: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/company-logo' as const, hash: url?.hash })
    },
    e_contract: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/e-contract' as const, hash: url?.hash })
    },
    estimate_templates: {
      _id: (id: string | number) => ({
        edit: {
          $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/estimate-templates/[id]/edit' as const, query: { id }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/estimate-templates/[id]' as const, query: { id }, hash: url?.hash })
      }),
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/estimate-templates/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/estimate-templates' as const, hash: url?.hash })
    },
    imprint: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/imprint' as const, hash: url?.hash })
    },
    invoice: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/invoice' as const, hash: url?.hash })
    },
    project_templates: {
      _id: (id: string | number) => ({
        edit: {
          $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/project-templates/[id]/edit' as const, query: { id }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/project-templates/[id]' as const, query: { id }, hash: url?.hash })
      }),
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/project-templates/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/project-templates' as const, hash: url?.hash })
    },
    tags: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/tags' as const, hash: url?.hash })
    },
    todo_templates: {
      $url: (url?: { hash?: string }) => ({ pathname: '/company-settings/todo-templates' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/company-settings' as const, hash: url?.hash })
  },
  contracts: {
    _id: (id: string | number) => ({
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/contracts/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/contracts/[id]' as const, query: { id }, hash: url?.hash })
    }),
    new: {
      $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/contracts/new' as const, query: url.query, hash: url.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/contracts' as const, hash: url?.hash })
  },
  crassone: {
    accidents: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/accidents/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    companies: {
      _id: (id: string | number) => ({
        _tab: (tab?: string[]) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/crassone/companies/[id]/[[...tab]]' as const, query: { id, tab }, hash: url?.hash })
        })
      }),
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/companies/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/crassone/companies' as const, hash: url?.hash })
    },
    login: {
      $url: (url?: { hash?: string }) => ({ pathname: '/crassone/login' as const, hash: url?.hash })
    },
    operators: {
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/operators/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/crassone/operators' as const, hash: url?.hash })
    },
    password: {
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/password/edit' as const, hash: url?.hash })
      }
    },
    projects: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/projects/[id]' as const, query: { id }, hash: url?.hash })
      }),
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/crassone/projects/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/crassone/projects' as const, hash: url?.hash })
    },
    uploaded_contract_documents: {
      $url: (url?: { hash?: string }) => ({ pathname: '/crassone/uploaded-contract-documents' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/crassone' as const, hash: url?.hash })
  },
  customers: {
    _id: (id: string | number) => ({
      _tab: (tab?: string[]) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/customers/[id]/[[...tab]]' as const, query: { id, tab }, hash: url?.hash })
      })
    }),
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/customers/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/customers' as const, hash: url?.hash })
  },
  dev_admin: {
    $url: (url?: { hash?: string }) => ({ pathname: '/dev-admin' as const, hash: url?.hash })
  },
  e_contracts: {
    _id: (id: string | number) => ({
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      signing: {
        $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts/[id]/signing' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts/[id]' as const, query: { id }, hash: url?.hash })
    }),
    contractor_signed: {
      $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts/contractor-signed' as const, hash: url?.hash })
    },
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/e-contracts' as const, hash: url?.hash })
  },
  estimates: {
    _id: (id: string | number) => ({
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/estimates/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/estimates/[id]' as const, query: { id }, hash: url?.hash })
    }),
    new: {
      $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/estimates/new' as const, query: url?.query, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/estimates' as const, hash: url?.hash })
  },
  insurance_agency: {
    application_requests: {
      _id: (id: string | number) => ({
        edit: {
          $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/application-requests/[id]/edit' as const, query: { id }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/application-requests/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/application-requests' as const, hash: url?.hash })
    },
    insurance_application: {
      new: {
        $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/insurance-application/new' as const, hash: url?.hash })
      }
    },
    login: {
      $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/login' as const, hash: url?.hash })
    },
    projects: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/projects/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency/projects' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/insurance-agency' as const, hash: url?.hash })
  },
  insurance_application: {
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/insurance-application/new' as const, hash: url?.hash })
    }
  },
  invoices: {
    _id: (id: string | number) => ({
      _tab: (tab?: string[]) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/invoices/[id]/[[...tab]]' as const, query: { id, tab }, hash: url?.hash })
      }),
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/invoices/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      history: {
        $url: (url?: { hash?: string }) => ({ pathname: '/invoices/[id]/history' as const, query: { id }, hash: url?.hash })
      }
    }),
    claims: {
      $url: (url?: { hash?: string }) => ({ pathname: '/invoices/claims' as const, hash: url?.hash })
    },
    new: {
      $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/invoices/new' as const, query: url?.query, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/invoices' as const, hash: url?.hash })
  },
  login: {
    line: {
      no_company_error: {
        $url: (url?: { hash?: string }) => ({ pathname: '/login/line/no-company-error' as const, hash: url?.hash })
      },
      sign_in: {
        $url: (url?: { hash?: string }) => ({ pathname: '/login/line/sign-in' as const, hash: url?.hash })
      },
      sign_up: {
        _key: (key: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/login/line/sign-up/[key]' as const, query: { key }, hash: url?.hash })
        })
      }
    },
    oidc_sign_in: {
      _key: (key: string[]) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/login/oidc-sign-in/[...key]' as const, query: { key }, hash: url?.hash })
      })
    },
    oidc_sign_up: {
      _key: (key: string[]) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/login/oidc-sign-up/[...key]' as const, query: { key }, hash: url?.hash })
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  members: {
    edit_email: {
      $url: (url?: { hash?: string }) => ({ pathname: '/members/edit-email' as const, hash: url?.hash })
    },
    edit_name: {
      $url: (url?: { hash?: string }) => ({ pathname: '/members/edit-name' as const, hash: url?.hash })
    },
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/members/new' as const, hash: url?.hash })
    },
    password: {
      forgot: {
        $url: (url?: { hash?: string }) => ({ pathname: '/members/password/forgot' as const, hash: url?.hash })
      },
      reset: {
        $url: (url?: { hash?: string }) => ({ pathname: '/members/password/reset' as const, hash: url?.hash })
      }
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/members' as const, hash: url?.hash })
  },
  projects: {
    _id: (id: string | number) => ({
      _tab: (tab?: string[]) => ({
        $url: (url?: { query?: OptionalQuery3, hash?: string }) => ({ pathname: '/projects/[id]/[[...tab]]' as const, query: { id, tab, ...url?.query }, hash: url?.hash })
      }),
      add_project_files: {
        $url: (url?: { hash?: string }) => ({ pathname: '/projects/[id]/add-project-files' as const, query: { id }, hash: url?.hash })
      },
      edit: {
        $url: (url?: { hash?: string }) => ({ pathname: '/projects/[id]/edit' as const, query: { id }, hash: url?.hash })
      },
      order_received_revisions: {
        _orderReceivedRevisionId: (orderReceivedRevisionId: string | number) => ({
          edit: {
            $url: (url?: { hash?: string }) => ({ pathname: '/projects/[id]/order-received-revisions/[orderReceivedRevisionId]/edit' as const, query: { id, orderReceivedRevisionId }, hash: url?.hash })
          }
        }),
        new: {
          $url: (url?: { hash?: string }) => ({ pathname: '/projects/[id]/order-received-revisions/new' as const, query: { id }, hash: url?.hash })
        }
      }
    }),
    new: {
      $url: (url?: { hash?: string }) => ({ pathname: '/projects/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/projects' as const, hash: url?.hash })
  },
  todos: {
    $url: (url?: { query?: OptionalQuery4, hash?: string }) => ({ pathname: '/todos' as const, query: url?.query, hash: url?.hash })
  },
  tos: {
    $url: (url?: { hash?: string }) => ({ pathname: '/tos' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
