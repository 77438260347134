import React, { useState } from 'react';
import { Link } from 'components/Link';
import { signOut } from 'next-auth/client';
import { useLoginMember } from 'hooks/useLoginUser';
import styled from 'styled-components';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  makeStyles,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ExitToApp as LogoutIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { useReactiveVar } from '@apollo/client';
import { pageTitleVar } from 'client-state';
import { useScreenSize } from 'hooks/useScreenSize';
import { clearUuids } from 'lib/uuid';
import { AppMode } from 'contexts/AppModeContext';
import type { NavItem } from '../nav-items';
import { AppMenuItem } from './AppMenuItem';
import { AccountMenu } from './AccountMenu';
import { accountMenuItems } from './accountMenuItems';
import Image from 'next/image';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}));

const PushRight = styled.div`
  flex-grow: 1;
`;

type Props = {
  hideMenuButton?: boolean;
  navItems: NavItem[];
  appMode: AppMode;
};

export const AppBar = ({ navItems, appMode }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { upSm } = useScreenSize();
  const classes = useStyles();
  const loginUser = useLoginMember();

  const pageTitle = useReactiveVar(pageTitleVar);

  const handleMenuButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    clearUuids();
    signOut();
  };

  if (loginUser == null) {
    return <div>Loading...</div>;
  }

  return (
    <MuiAppBar
      position="static"
      color="inherit"
      elevation={4}
      className={classes.appBar}
    >
      <Toolbar variant="dense" style={{ justifyContent: 'center' }}>
        {upSm ? (
          <>
            <Image
              src="/images/crassone_biz_logo.svg"
              width={166.8}
              height={24}
            />
            <PushRight />
            <div style={{ marginRight: 8, fontSize: 12 }}>
              <div>{loginUser?.company.name}</div>
              <div>{loginUser?.name ?? '-'} 様</div>
            </div>
            {appMode == 'company' ? (
              <AccountMenu />
            ) : (
              <Button variant="outlined" size="small" onClick={handleSignOut}>
                ログアウト
              </Button>
            )}
          </>
        ) : (
          <>
            <div style={{ position: 'absolute', left: 0, top: 0 }}>
              <IconButton
                onClick={handleMenuButtonClick}
                data-testid="app-menu-button"
                color="primary"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <h1 style={{ fontSize: 16 }}>{pageTitle}</h1>
          </>
        )}
      </Toolbar>
      {!upSm && (
        <Drawer anchor="left" open={open} onClose={handleClose}>
          <div style={{ margin: 8, fontSize: 12 }}>
            <div>{loginUser?.company.name}</div>
            <div>{loginUser?.name ?? '-'} 様</div>
          </div>
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <List style={{ minWidth: 240 }}>
            {navItems.map((item, index) => (
              <Link
                key={index}
                href={item.path}
                external={item.target != null}
                target={item.target}
              >
                <AppMenuItem icon={item.icon} label={item.label} />
              </Link>
            ))}

            {appMode == 'company' &&
              accountMenuItems.map((item, index) => (
                <Link key={index} href={item.path}>
                  <AppMenuItem label={item.label} />
                </Link>
              ))}

            <AppMenuItem
              icon={LogoutIcon}
              label="ログアウト"
              onClick={handleSignOut}
            />
          </List>
        </Drawer>
      )}
    </MuiAppBar>
  );
};
