import { useContext } from 'react';
import {
  LoginUserContext,
  LoginUserWithToken,
} from 'contexts/LoginUserContext';

export type LoginMember = Extract<LoginUserWithToken, { __typename: 'Member' }>;

export const useLoginUser = (): LoginUserWithToken | null => {
  const loginUser = useContext(LoginUserContext) ?? null;

  return loginUser;
};

export const useLoginMember = (): LoginMember | null => {
  const loginUser = useLoginUser();

  return loginUser?.__typename === 'Member' ? loginUser : null;
};
