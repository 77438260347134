import { FC, CSSProperties } from 'react';
import {
  ListItem,
  ListItemIcon,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { textOnDarkBackground, iconOnDarkBackground } from 'color-palette';

const useStyles = makeStyles<Theme, Pick<Props, 'colors'>>((theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(1),
      '&:hover': {
        background: (props) => props.colors.hover,
      },
    },
    activeListItem: {
      padding: theme.spacing(1),
      backgroundColor: (props) => props.colors.active,
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
    },
    listItemLabel: {
      fontSize: 10,
      color: () => textOnDarkBackground.high,
    },
  }),
);

interface IconProps {
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
  style?: CSSProperties;
}

type Props = {
  icon: FC<IconProps>;
  label: string;
  active?: boolean;
  colors: {
    active: string;
    hover: string;
  };
};

export const SideNavItem = ({
  icon: Icon,
  label,
  active = false,
  colors,
}: Props): JSX.Element => {
  const classes = useStyles({ colors });

  return (
    <ListItem
      button
      className={active ? classes.activeListItem : classes.listItem}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <Icon
          fontSize="large"
          style={
            active
              ? { fill: iconOnDarkBackground.active }
              : { fill: iconOnDarkBackground.inactive }
          }
        />
        <div className={classes.listItemLabel}>{label}</div>
      </ListItemIcon>
    </ListItem>
  );
};
