import {
  CompanyFeature,
  FeatureAccessLevel,
  MemberAccessPolicy,
} from 'generated/graphql';

type User = {
  memberAccessPolicies: Pick<
    MemberAccessPolicy,
    'targetFeature' | 'accessLevel'
  >[];
};

// 利便性のためのre-export
// enumは型(type)と値(value)の両方を持つのでこういう記述が必要
export type AccessLevel = FeatureAccessLevel;
export const AccessLevel = FeatureAccessLevel;
export type Feature = CompanyFeature;
export const Feature = CompanyFeature;

export const hasPermission = (
  user: User | null | undefined,
  feature: CompanyFeature,
  requiredLevel: AccessLevel,
): boolean => {
  if (user == null) return false;

  return user.memberAccessPolicies.some((policy) => {
    if (policy.targetFeature !== feature) return false;

    return toNumericValue(policy.accessLevel) >= toNumericValue(requiredLevel);
  });
};

// 数値による比較を簡単にするためのユーティリティ
// Read < Update < Create の順で権限が強くなる（包含関係にある）
function toNumericValue(level: AccessLevel): number {
  switch (level) {
    case AccessLevel.Read:
      return 1;
    case AccessLevel.Update:
      return 10;
    case AccessLevel.Create:
      return 100;
  }
}
